@font-face {
  font-family: 'boomho';
  src:  url('fonts/boomho.eot?vwgr7m');
  src:  url('fonts/boomho.eot?vwgr7m#iefix') format('embedded-opentype'),
    url('fonts/boomho.ttf?vwgr7m') format('truetype'),
    url('fonts/boomho.woff?vwgr7m') format('woff'),
    url('fonts/boomho.svg?vwgr7m#boomho') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon--"], [class*=" icon--"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'boomho' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--document-docent .path1:before {
  content: "\eb3c";
  color: rgb(0, 0, 0);
}
.icon--document-docent .path2:before {
  content: "\eb3d";
  margin-left: -1.130859375em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon--exam .path1:before {
  content: "\eb3e";
  color: rgb(0, 36, 57);
}
.icon--exam .path2:before {
  content: "\eb3f";
  margin-left: -1em;
  color: rgb(0, 36, 57);
  opacity: 0.5;
}
.icon--document-law .path1:before {
  content: "\eb37";
  color: rgb(0, 0, 0);
}
.icon--document-law .path2:before {
  content: "\eb38";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon--document-law .path3:before {
  content: "\eb39";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon--document-law .path4:before {
  content: "\eb3a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon--document-law .path5:before {
  content: "\eb3b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon--document-docent2 .path1:before {
  content: "\eb35";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon--document-docent2 .path2:before {
  content: "\eb36";
  margin-left: -0.9501953125em;
  color: rgb(0, 0, 0);
}
.icon--download:before {
  content: "\eb34";
  color: #6a747a;
}
.icon--anywyse:before {
  content: "\eb33";
  color: #00985b;
}
.icon--document-taskplan:before {
  content: "\e900";
}
.icon--exam-purple .path1:before {
  content: "\eb2e";
  color: rgb(167, 24, 120);
}
.icon--exam-purple .path2:before {
  content: "\eb2f";
  margin-left: -0.9658203125em;
  color: rgb(167, 24, 120);
  opacity: 0.5;
}
.icon--exam-white .path1:before {
  content: "\eb30";
  color: rgb(255, 255, 255);
}
.icon--exam-white .path2:before {
  content: "\eb31";
  margin-left: -0.9658203125em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.icon--icn_check:before {
  content: "\eb2d";
}
.icon--icn_cross:before {
  content: "\eb2a";
}
.icon--icon_externalBook .path1:before {
  content: "\eb2b";
  color: rgb(255, 255, 255);
}
.icon--icon_externalBook .path2:before {
  content: "\eb2c";
  margin-left: -0.8515625em;
  color: rgb(0, 0, 0);
}
.icon--close-exam .path1:before {
  content: "\eb28";
  color: rgb(255, 255, 255);
}
.icon--close-exam .path2:before {
  content: "\eb29";
  margin-left: -0.849609375em;
  color: rgb(195, 137, 176);
}
.icon--list-check:before {
  content: "\eb27";
}
.icon--document-quotation .path1:before {
  content: "\eb25";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon--document-quotation .path2:before {
  content: "\eb26";
  margin-left: -0.9501953125em;
  color: rgb(0, 0, 0);
}
.icon--feedback-child:before {
  content: "\eb24";
}
.icon--question-mark:before {
  content: "\eb23";
}
.icon--add-feedback:before {
  content: "\eb21";
}
.icon--feedback:before {
  content: "\eb22";
}
.icon--document-definitie .path1:before {
  content: "\eb1f";
  color: rgb(167, 24, 120);
  opacity: 0.4;
}
.icon--document-definitie .path2:before {
  content: "\eb20";
  margin-left: -0.875em;
  color: rgb(167, 24, 120);
}
.icon--document-verdieping:before {
  content: "\eb1e";
}
.icon--right-left:before {
  content: "\eb1d";
}
.icon--training .path1:before {
  content: "\eb1b";
  color: rgb(106, 116, 122);
}
.icon--training .path2:before {
  content: "\eb1c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon--settings:before {
  content: "\eb19";
  color: #6a747a;
}
.icon--fi-rr-pause-2:before {
  content: "\eb1a";
}
.icon--unreaded:before {
  content: "\eb17";
  color: #95a3ac;
}
.icon--read:before {
  content: "\eb18";
  color: #95a3ac;
}
.icon--list-view:before {
  content: "\eb15";
  color: #c4c4c4;
}
.icon--column-view:before {
  content: "\eb16";
  color: #c4c4c4;
}
.icon--burger:before {
  content: "\eb14";
}
.icon--redo2:before {
  content: "\eb12";
}
.icon--undo2:before {
  content: "\eb13";
}
.icon--check_mark:before {
  content: "\eb10";
}
.icon--hint:before {
  content: "\eb11";
}
.icon--document-goals:before {
  content: "\eb0f";
}
.icon--external-link:before {
  content: "\eb0e";
  color: #fff;
}
.icon--chevron_down:before {
  content: "\eb0c";
}
.icon--pencil-redactor:before {
  content: "\eb0d";
  color: #6a747a;
}
.icon--external-link-alt:before {
  content: "\eb0b";
  color: #fff;
}
.icon--folder-back:before {
  content: "\eb0a";
}
.icon--reading:before {
  content: "\eb08";
}
.icon--drillster:before {
  content: "\eb09";
}
.icon--folder .path1:before {
  content: "\eb06";
  color: rgb(106, 116, 122);
}
.icon--folder .path2:before {
  content: "\eb07";
  margin-left: -1em;
  color: rgb(106, 116, 122);
  opacity: 0.4;
}
.icon--sources .path1:before {
  content: "\eaff";
  color: rgb(106, 116, 122);
}
.icon--sources .path2:before {
  content: "\eb00";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--sources .path3:before {
  content: "\eb01";
  margin-left: -1em;
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--results-student .path1:before {
  content: "\eb02";
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--results-student .path2:before {
  content: "\eb03";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--results-student .path3:before {
  content: "\eb04";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--results-student .path4:before {
  content: "\eb05";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--book-open-reader:before {
  content: "\eafe";
}
.icon--mail:before {
  content: "\eafc";
}
.icon--contact:before {
  content: "\eafd";
}
.icon--camera:before {
  content: "\eafb";
  color: #fff;
}
.icon--calendar:before {
  content: "\eafa";
}
.icon--graph:before {
  content: "\eaf9";
}
.icon--eye:before {
  content: "\eaf8";
}
.icon--notion:before {
  content: "\eaf7";
}
.icon--list:before {
  content: "\eaf6";
}
.icon--up:before {
  content: "\eaf2";
  color: #6a747a;
}
.icon--search:before {
  content: "\eaf3";
}
.icon--filters:before {
  content: "\eaf4";
  color: #6a747a;
}
.icon--down:before {
  content: "\eaf5";
}
.icon--book-abc .path1:before {
  content: "\eaed";
  color: rgb(0, 0, 0);
}
.icon--book-abc .path2:before {
  content: "\eaee";
  margin-left: -1em;
  color: rgb(26, 26, 26);
  opacity: 0.5;
}
.icon--book-abc .path3:before {
  content: "\eaef";
  margin-left: -1em;
  color: rgb(26, 26, 26);
  opacity: 0.5;
}
.icon--book-abc .path4:before {
  content: "\eaf0";
  margin-left: -1em;
  color: rgb(26, 26, 26);
  opacity: 0.5;
}
.icon--book-abc .path5:before {
  content: "\eaf1";
  margin-left: -1em;
  color: rgb(26, 26, 26);
  opacity: 0.5;
}
.icon--source:before {
  content: "\eaec";
}
.icon--filter:before {
  content: "\eaeb";
  color: #6a747a;
}
.icon--vote-down .path1:before {
  content: "\eae4";
  color: rgb(226, 229, 231);
}
.icon--vote-down .path2:before {
  content: "\eae5";
  margin-left: -1em;
  color: rgb(137, 151, 160);
}
.icon--vote-down .path3:before {
  content: "\eae6";
  margin-left: -1em;
  color: rgb(137, 151, 160);
}
.icon--vote-up .path1:before {
  content: "\eae7";
  color: rgb(226, 229, 231);
}
.icon--vote-up .path2:before {
  content: "\eae8";
  margin-left: -1em;
  color: rgb(137, 151, 160);
}
.icon--vote:before {
  content: "\eae3";
  color: #6a747a;
}
.icon--menu-dots-vertical:before {
  content: "\eae2";
  color: #6a747a;
}
.icon--copy:before {
  content: "\eae0";
  color: #8997a0;
}
.icon--refresh:before {
  content: "\eae1";
  color: #8997a0;
}
.icon--add:before {
  content: "\eadf";
}
.icon--exclamation-circle:before {
  content: "\eade";
}
.icon--profile-user:before {
  content: "\eadc";
  color: #6a747a;
}
.icon--logout:before {
  content: "\eadd";
  color: #6a747a;
}
.icon--file .path1:before {
  content: "\ead4";
  color: rgb(226, 229, 231);
}
.icon--file .path2:before {
  content: "\ead5";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--file .path3:before {
  content: "\ead6";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--file .path4:before {
  content: "\ead7";
  margin-left: -1em;
  color: rgb(149, 163, 172);
}
.icon--file .path5:before {
  content: "\ead8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--file .path6:before {
  content: "\ead9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--file .path7:before {
  content: "\eada";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--file .path8:before {
  content: "\eadb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--link-anders:before {
  content: "\ead3";
}
.icon--train:before {
  content: "\ead2";
}
.icon--switch:before {
  content: "\ead1";
}
.icon--memorize .path1:before {
  content: "\eae9";
  color: rgb(0, 0, 0);
}
.icon--memorize .path2:before {
  content: "\eaea";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon--document-up:before {
  content: "\eacd";
  color: #1ba764;
}
.icon--document-citation:before {
  content: "\eace";
}
.icon--document-tip:before {
  content: "\eacf";
}
.icon--document-remark:before {
  content: "\ead0";
}
.icon--next:before {
  content: "\eac9";
  color: #fff;
}
.icon--previous:before {
  content: "\eaca";
  color: #fff;
}
.icon--berichten .path1:before {
  content: "\e901";
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--berichten .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--berichten .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--berichten .path4:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--inhoud .path1:before {
  content: "\e905";
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--inhoud .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--inhoud .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--inhoud .path4:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--inhoud .path5:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--instellingen:before {
  content: "\e90a";
  color: #6a747a;
}
.icon--klassen .path1:before {
  content: "\e90b";
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--klassen .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--klassen .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--klassen .path4:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--klassen .path5:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--klassen .path6:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--licenties .path1:before {
  content: "\e911";
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--licenties .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--licenties .path3:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--methodes .path1:before {
  content: "\e914";
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--methodes .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(106, 116, 122);
}
.icon--methodes .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--voortgang .path1:before {
  content: "\e917";
  color: rgb(106, 116, 122);
}
.icon--voortgang .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(106, 116, 122);
  opacity: 0.5;
}
.icon--close:before {
  content: "\eacc";
}
.icon--info:before {
  content: "\eacb";
}
.icon--upload:before {
  content: "\e919";
}
.icon--usb-drive:before {
  content: "\e91a";
}
.icon--user-add:before {
  content: "\e91b";
}
.icon--user-delete:before {
  content: "\e91c";
}
.icon--user-remove:before {
  content: "\e91d";
}
.icon--user-time:before {
  content: "\e91e";
}
.icon--user:before {
  content: "\e91f";
}
.icon--users:before {
  content: "\e920";
}
.icon--vector-alt:before {
  content: "\e921";
}
.icon--vector:before {
  content: "\e922";
}
.icon--video-camera:before {
  content: "\e923";
}
.icon--volleyball:before {
  content: "\e924";
}
.icon--volume:before {
  content: "\e925";
}
.icon--wifi-alt:before {
  content: "\e926";
}
.icon--world:before {
  content: "\e927";
}
.icon--yen:before {
  content: "\e928";
}
.icon--zoom-in:before {
  content: "\e929";
}
.icon--zoom-out:before {
  content: "\e92a";
}
.icon--shield:before {
  content: "\e92b";
}
.icon--shop:before {
  content: "\e92c";
}
.icon--shopping-bag-add:before {
  content: "\e92d";
}
.icon--shopping-bag:before {
  content: "\e92e";
}
.icon--shopping-cart-add:before {
  content: "\e92f";
}
.icon--shopping-cart-check:before {
  content: "\e930";
}
.icon--shopping-cart:before {
  content: "\e931";
}
.icon--shuffle:before {
  content: "\e932";
}
.icon--sign-in:before {
  content: "\e933";
}
.icon--sign-out:before {
  content: "\e934";
}
.icon--signal-alt-1:before {
  content: "\e935";
}
.icon--signal-alt-2:before {
  content: "\e936";
}
.icon--signal-alt:before {
  content: "\e937";
}
.icon--skateboard:before {
  content: "\e938";
}
.icon--smartphone:before {
  content: "\e939";
}
.icon--soap:before {
  content: "\e93a";
}
.icon--spa:before {
  content: "\e93b";
}
.icon--speaker:before {
  content: "\e93c";
}
.icon--spinner-alt:before {
  content: "\e93d";
}
.icon--spinner:before {
  content: "\e93e";
}
.icon--square-root:before {
  content: "\e93f";
}
.icon--square:before {
  content: "\e940";
}
.icon--star:before {
  content: "\e941";
}
.icon--stats:before {
  content: "\e942";
}
.icon--stethoscope:before {
  content: "\e943";
}
.icon--sticker:before {
  content: "\e944";
}
.icon--stop:before {
  content: "\e945";
}
.icon--stopwatch:before {
  content: "\e946";
}
.icon--subtitles:before {
  content: "\e947";
}
.icon--surfing:before {
  content: "\e948";
}
.icon--sword:before {
  content: "\e949";
}
.icon--syringe:before {
  content: "\e94a";
}
.icon--tablet:before {
  content: "\e94b";
}
.icon--target:before {
  content: "\e94c";
}
.icon--tennis:before {
  content: "\e94d";
}
.icon--test-tube:before {
  content: "\e94e";
}
.icon--test:before {
  content: "\e94f";
}
.icon--text-check:before {
  content: "\e950";
}
.icon--text:before {
  content: "\e951";
}
.icon--thumbs-down:before {
  content: "\e952";
}
.icon--thumbs-up:before {
  content: "\e953";
}
.icon--ticket:before {
  content: "\e954";
}
.icon--time-add:before {
  content: "\e955";
}
.icon--time-check:before {
  content: "\e956";
}
.icon--time-delete:before {
  content: "\e957";
}
.icon--time-fast:before {
  content: "\e958";
}
.icon--time-forward-sixty:before {
  content: "\e959";
}
.icon--time-forward-ten:before {
  content: "\e95a";
}
.icon--time-forward:before {
  content: "\e95b";
}
.icon--time-half-past:before {
  content: "\e95c";
}
.icon--time-oclock:before {
  content: "\e95d";
}
.icon--time-past:before {
  content: "\e95e";
}
.icon--time-quarter-past:before {
  content: "\e95f";
}
.icon--time-quarter-to:before {
  content: "\e960";
}
.icon--time-twenty-four:before {
  content: "\e961";
}
.icon--tool-crop:before {
  content: "\e962";
}
.icon--tool-marquee:before {
  content: "\e963";
}
.icon--tooth:before {
  content: "\e964";
}
.icon--transform:before {
  content: "\e965";
}
.icon--trash:before {
  content: "\e966";
}
.icon--treatment:before {
  content: "\e967";
}
.icon--trophy:before {
  content: "\e968";
}
.icon--umbrella:before {
  content: "\e969";
}
.icon--underline:before {
  content: "\e96a";
}
.icon--undo:before {
  content: "\e96b";
}
.icon--unlock:before {
  content: "\e96c";
}
.icon--microphone:before {
  content: "\e96d";
}
.icon--minus-small:before {
  content: "\e96e";
}
.icon--minus:before {
  content: "\e96f";
}
.icon--mobile:before {
  content: "\e970";
}
.icon--mode-landscape:before {
  content: "\e971";
}
.icon--mode-portrait:before {
  content: "\e972";
}
.icon--money:before {
  content: "\e973";
}
.icon--mouse:before {
  content: "\e974";
}
.icon--music-alt:before {
  content: "\e975";
}
.icon--music:before {
  content: "\e976";
}
.icon--network-cloud:before {
  content: "\e977";
}
.icon--network:before {
  content: "\e978";
}
.icon--notebook:before {
  content: "\e979";
}
.icon--opacity:before {
  content: "\e97a";
}
.icon--package:before {
  content: "\e97b";
}
.icon--paint-brush:before {
  content: "\e97c";
}
.icon--palette:before {
  content: "\e97d";
}
.icon--paper-plane:before {
  content: "\e97e";
}
.icon--password:before {
  content: "\e97f";
}
.icon--pause:before {
  content: "\e980";
}
.icon--pencil:before {
  content: "\e981";
}
.icon--pharmacy:before {
  content: "\e982";
}
.icon--physics:before {
  content: "\e983";
}
.icon--picture:before {
  content: "\e984";
}
.icon--ping-pong:before {
  content: "\e985";
}
.icon--play-alt:before {
  content: "\e986";
}
.icon--play:before {
  content: "\e987";
}
.icon--playing-cards:before {
  content: "\e988";
}
.icon--plus-small:before {
  content: "\e989";
}
.icon--avi .path1:before {
  content: "\e98a";
  color: rgb(226, 229, 231);
}
.icon--avi .path2:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--avi .path3:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--avi .path4:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(241, 86, 66);
}
.icon--avi .path5:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--avi .path6:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--avi .path7:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--avi .path8:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--ds .path1:before {
  content: "\e992";
  color: rgb(226, 229, 231);
}
.icon--ds .path2:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--ds .path3:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--ds .path4:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(132, 189, 90);
}
.icon--ds .path5:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--ds .path6:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--ds .path7:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--ds .path8:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--aac .path1:before {
  content: "\e99a";
  color: rgb(226, 229, 231);
}
.icon--aac .path2:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--aac .path3:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--aac .path4:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(241, 86, 66);
}
.icon--aac .path5:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--aac .path6:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--aac .path7:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--aac .path8:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--ai .path1:before {
  content: "\e9a2";
  color: rgb(226, 229, 231);
}
.icon--ai .path2:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--ai .path3:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--ai .path4:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(247, 184, 78);
}
.icon--ai .path5:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--ai .path6:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--ai .path7:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--bmp .path1:before {
  content: "\e9a9";
  color: rgb(226, 229, 231);
}
.icon--bmp .path2:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--bmp .path3:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--bmp .path4:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(132, 189, 90);
}
.icon--bmp .path5:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--bmp .path6:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--bmp .path7:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--bmp .path8:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--cad .path1:before {
  content: "\e9b1";
  color: rgb(226, 229, 231);
}
.icon--cad .path2:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--cad .path3:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--cad .path4:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(80, 190, 232);
}
.icon--cad .path5:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--cad .path6:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--cad .path7:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--cad .path8:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--cdr .path1:before {
  content: "\e9b9";
  color: rgb(226, 229, 231);
}
.icon--cdr .path2:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--cdr .path3:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--cdr .path4:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(132, 189, 90);
}
.icon--cdr .path5:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--cdr .path6:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--cdr .path7:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--cdr .path8:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--css .path1:before {
  content: "\e9c1";
  color: rgb(226, 229, 231);
}
.icon--css .path2:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--css .path3:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--css .path4:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(87, 109, 126);
}
.icon--css .path5:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--css .path6:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--css .path7:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--css .path8:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--dat .path1:before {
  content: "\e9c9";
  color: rgb(226, 229, 231);
}
.icon--dat .path2:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--dat .path3:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--dat .path4:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(132, 189, 90);
}
.icon--dat .path5:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--dat .path6:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--dat .path7:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--dat .path8:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--dll .path1:before {
  content: "\e9d1";
  color: rgb(226, 229, 231);
}
.icon--dll .path2:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--dll .path3:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--dll .path4:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(87, 109, 126);
}
.icon--dll .path5:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--dll .path6:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--dll .path7:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--dll .path8:before {
  content: "\e9d8";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--dmg .path1:before {
  content: "\e9d9";
  color: rgb(226, 229, 231);
}
.icon--dmg .path2:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--dmg .path3:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--dmg .path4:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(87, 109, 126);
}
.icon--dmg .path5:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--dmg .path6:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--dmg .path7:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--dmg .path8:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--doc .path1:before {
  content: "\e9e1";
  color: rgb(226, 229, 231);
}
.icon--doc .path2:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--doc .path3:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--doc .path4:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(80, 190, 232);
}
.icon--doc .path5:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--doc .path6:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--doc .path7:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--doc .path8:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--eps .path1:before {
  content: "\e9e9";
  color: rgb(226, 229, 231);
}
.icon--eps .path2:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--eps .path3:before {
  content: "\e9eb";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--eps .path4:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(247, 184, 78);
}
.icon--eps .path5:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--eps .path6:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--eps .path7:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--eps .path8:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--fla .path1:before {
  content: "\e9f1";
  color: rgb(226, 229, 231);
}
.icon--fla .path2:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--fla .path3:before {
  content: "\e9f3";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--fla .path4:before {
  content: "\e9f4";
  margin-left: -1em;
  color: rgb(241, 86, 66);
}
.icon--fla .path5:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--fla .path6:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--fla .path7:before {
  content: "\e9f7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--fla .path8:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--flv .path1:before {
  content: "\e9f9";
  color: rgb(226, 229, 231);
}
.icon--flv .path2:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--flv .path3:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--flv .path4:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(247, 184, 78);
}
.icon--flv .path5:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--flv .path6:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--flv .path7:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--flv .path8:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--gif .path1:before {
  content: "\ea01";
  color: rgb(226, 229, 231);
}
.icon--gif .path2:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--gif .path3:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--gif .path4:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(160, 102, 170);
}
.icon--gif .path5:before {
  content: "\ea05";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--gif .path6:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--gif .path7:before {
  content: "\ea07";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--gif .path8:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--html .path1:before {
  content: "\ea09";
  color: rgb(226, 229, 231);
}
.icon--html .path2:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--html .path3:before {
  content: "\ea0b";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--html .path4:before {
  content: "\ea0c";
  margin-left: -1em;
  color: rgb(132, 189, 90);
}
.icon--html .path5:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--html .path6:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--html .path7:before {
  content: "\ea0f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--html .path8:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--html .path9:before {
  content: "\ea11";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--indd .path1:before {
  content: "\ea12";
  color: rgb(226, 229, 231);
}
.icon--indd .path2:before {
  content: "\ea13";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--indd .path3:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--indd .path4:before {
  content: "\ea15";
  margin-left: -1em;
  color: rgb(160, 102, 170);
}
.icon--indd .path5:before {
  content: "\ea16";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--indd .path6:before {
  content: "\ea17";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--indd .path7:before {
  content: "\ea18";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--indd .path8:before {
  content: "\ea19";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--indd .path9:before {
  content: "\ea1a";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--iso .path1:before {
  content: "\ea1b";
  color: rgb(226, 229, 231);
}
.icon--iso .path2:before {
  content: "\ea1c";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--iso .path3:before {
  content: "\ea1d";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--iso .path4:before {
  content: "\ea1e";
  margin-left: -1em;
  color: rgb(80, 190, 232);
}
.icon--iso .path5:before {
  content: "\ea1f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--iso .path6:before {
  content: "\ea20";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--iso .path7:before {
  content: "\ea21";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--iso .path8:before {
  content: "\ea22";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--jpg .path1:before {
  content: "\ea23";
  color: rgb(226, 229, 231);
}
.icon--jpg .path2:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--jpg .path3:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--jpg .path4:before {
  content: "\ea26";
  margin-left: -1em;
  color: rgb(80, 190, 232);
}
.icon--jpg .path5:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--jpg .path6:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--jpg .path7:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--jpg .path8:before {
  content: "\ea2a";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--js .path1:before {
  content: "\ea2b";
  color: rgb(226, 229, 231);
}
.icon--js .path2:before {
  content: "\ea2c";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--js .path3:before {
  content: "\ea2d";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--js .path4:before {
  content: "\ea2e";
  margin-left: -1em;
  color: rgb(87, 109, 126);
}
.icon--js .path5:before {
  content: "\ea2f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--js .path6:before {
  content: "\ea30";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--js .path7:before {
  content: "\ea31";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--midi .path1:before {
  content: "\ea32";
  color: rgb(226, 229, 231);
}
.icon--midi .path2:before {
  content: "\ea33";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--midi .path3:before {
  content: "\ea34";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--midi .path4:before {
  content: "\ea35";
  margin-left: -1em;
  color: rgb(160, 102, 170);
}
.icon--midi .path5:before {
  content: "\ea36";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--midi .path6:before {
  content: "\ea37";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--midi .path7:before {
  content: "\ea38";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--midi .path8:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--midi .path9:before {
  content: "\ea3a";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--mov .path1:before {
  content: "\ea3b";
  color: rgb(226, 229, 231);
}
.icon--mov .path2:before {
  content: "\ea3c";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--mov .path3:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--mov .path4:before {
  content: "\ea3e";
  margin-left: -1em;
  color: rgb(80, 190, 232);
}
.icon--mov .path5:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--mov .path6:before {
  content: "\ea40";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--mov .path7:before {
  content: "\ea41";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--mov .path8:before {
  content: "\ea42";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--mp3 .path1:before {
  content: "\ea43";
  color: rgb(226, 229, 231);
}
.icon--mp3 .path2:before {
  content: "\ea44";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--mp3 .path3:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--mp3 .path4:before {
  content: "\ea46";
  margin-left: -1em;
  color: rgb(80, 190, 232);
}
.icon--mp3 .path5:before {
  content: "\ea47";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--mp3 .path6:before {
  content: "\ea48";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--mp3 .path7:before {
  content: "\ea49";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--mp3 .path8:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--mpg .path1:before {
  content: "\ea4b";
  color: rgb(226, 229, 231);
}
.icon--mpg .path2:before {
  content: "\ea4c";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--mpg .path3:before {
  content: "\ea4d";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--mpg .path4:before {
  content: "\ea4e";
  margin-left: -1em;
  color: rgb(247, 184, 78);
}
.icon--mpg .path5:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--mpg .path6:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--mpg .path7:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--mpg .path8:before {
  content: "\ea52";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--pdf .path1:before {
  content: "\ea53";
  color: rgb(226, 229, 231);
}
.icon--pdf .path2:before {
  content: "\ea54";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--pdf .path3:before {
  content: "\ea55";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--pdf .path4:before {
  content: "\ea56";
  margin-left: -1em;
  color: rgb(241, 86, 66);
}
.icon--pdf .path5:before {
  content: "\ea57";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--pdf .path6:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--pdf .path7:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--pdf .path8:before {
  content: "\ea5a";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--php .path1:before {
  content: "\ea5b";
  color: rgb(226, 229, 231);
}
.icon--php .path2:before {
  content: "\ea5c";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--php .path3:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--php .path4:before {
  content: "\ea5e";
  margin-left: -1em;
  color: rgb(87, 109, 126);
}
.icon--php .path5:before {
  content: "\ea5f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--php .path6:before {
  content: "\ea60";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--php .path7:before {
  content: "\ea61";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--php .path8:before {
  content: "\ea62";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--png .path1:before {
  content: "\ea63";
  color: rgb(226, 229, 231);
}
.icon--png .path2:before {
  content: "\ea64";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--png .path3:before {
  content: "\ea65";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--png .path4:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(160, 102, 170);
}
.icon--png .path5:before {
  content: "\ea67";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--png .path6:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--png .path7:before {
  content: "\ea69";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--png .path8:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--ppt .path1:before {
  content: "\ea6b";
  color: rgb(226, 229, 231);
}
.icon--ppt .path2:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--ppt .path3:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--ppt .path4:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(241, 86, 66);
}
.icon--ppt .path5:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--ppt .path6:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--ppt .path7:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--ppt .path8:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--ps .path1:before {
  content: "\ea73";
  color: rgb(226, 229, 231);
}
.icon--ps .path2:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--ps .path3:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--ps .path4:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(247, 184, 78);
}
.icon--ps .path5:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--ps .path6:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--ps .path7:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--psd .path1:before {
  content: "\ea7a";
  color: rgb(226, 229, 231);
}
.icon--psd .path2:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--psd .path3:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--psd .path4:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(80, 190, 232);
}
.icon--psd .path5:before {
  content: "\ea7e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--psd .path6:before {
  content: "\ea7f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--psd .path7:before {
  content: "\ea80";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--psd .path8:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--raw .path1:before {
  content: "\ea82";
  color: rgb(226, 229, 231);
}
.icon--raw .path2:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--raw .path3:before {
  content: "\ea84";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--raw .path4:before {
  content: "\ea85";
  margin-left: -1em;
  color: rgb(87, 109, 126);
}
.icon--raw .path5:before {
  content: "\ea86";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--raw .path6:before {
  content: "\ea87";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--raw .path7:before {
  content: "\ea88";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--raw .path8:before {
  content: "\ea89";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--sql .path1:before {
  content: "\ea8a";
  color: rgb(226, 229, 231);
}
.icon--sql .path2:before {
  content: "\ea8b";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--sql .path3:before {
  content: "\ea8c";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--sql .path4:before {
  content: "\ea8d";
  margin-left: -1em;
  color: rgb(241, 86, 66);
}
.icon--sql .path5:before {
  content: "\ea8e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--sql .path6:before {
  content: "\ea8f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--sql .path7:before {
  content: "\ea90";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--sql .path8:before {
  content: "\ea91";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--svg .path1:before {
  content: "\ea92";
  color: rgb(226, 229, 231);
}
.icon--svg .path2:before {
  content: "\ea93";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--svg .path3:before {
  content: "\ea94";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--svg .path4:before {
  content: "\ea95";
  margin-left: -1em;
  color: rgb(247, 184, 78);
}
.icon--svg .path5:before {
  content: "\ea96";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--svg .path6:before {
  content: "\ea97";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--svg .path7:before {
  content: "\ea98";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--svg .path8:before {
  content: "\ea99";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--tif .path1:before {
  content: "\ea9a";
  color: rgb(226, 229, 231);
}
.icon--tif .path2:before {
  content: "\ea9b";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--tif .path3:before {
  content: "\ea9c";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--tif .path4:before {
  content: "\ea9d";
  margin-left: -1em;
  color: rgb(160, 102, 170);
}
.icon--tif .path5:before {
  content: "\ea9e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--tif .path6:before {
  content: "\ea9f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--tif .path7:before {
  content: "\eaa0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--tif .path8:before {
  content: "\eaa1";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--txt .path1:before {
  content: "\eaa2";
  color: rgb(226, 229, 231);
}
.icon--txt .path2:before {
  content: "\eaa3";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--txt .path3:before {
  content: "\eaa4";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--txt .path4:before {
  content: "\eaa5";
  margin-left: -1em;
  color: rgb(87, 109, 126);
}
.icon--txt .path5:before {
  content: "\eaa6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--txt .path6:before {
  content: "\eaa7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--txt .path7:before {
  content: "\eaa8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--txt .path8:before {
  content: "\eaa9";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--wmv .path1:before {
  content: "\eaaa";
  color: rgb(226, 229, 231);
}
.icon--wmv .path2:before {
  content: "\eaab";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--wmv .path3:before {
  content: "\eaac";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--wmv .path4:before {
  content: "\eaad";
  margin-left: -1em;
  color: rgb(132, 189, 90);
}
.icon--wmv .path5:before {
  content: "\eaae";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--wmv .path6:before {
  content: "\eaaf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--wmv .path7:before {
  content: "\eab0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--wmv .path8:before {
  content: "\eab1";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--xls .path1:before {
  content: "\eab2";
  color: rgb(226, 229, 231);
}
.icon--xls .path2:before {
  content: "\eab3";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--xls .path3:before {
  content: "\eab4";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--xls .path4:before {
  content: "\eab5";
  margin-left: -1em;
  color: rgb(132, 189, 90);
}
.icon--xls .path5:before {
  content: "\eab6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--xls .path6:before {
  content: "\eab7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--xls .path7:before {
  content: "\eab8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--xls .path8:before {
  content: "\eab9";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--xml .path1:before {
  content: "\eaba";
  color: rgb(226, 229, 231);
}
.icon--xml .path2:before {
  content: "\eabb";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--xml .path3:before {
  content: "\eabc";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--xml .path4:before {
  content: "\eabd";
  margin-left: -1em;
  color: rgb(80, 190, 232);
}
.icon--xml .path5:before {
  content: "\eabe";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--xml .path6:before {
  content: "\eabf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--xml .path7:before {
  content: "\eac0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--xml .path8:before {
  content: "\eac1";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--zip .path1:before {
  content: "\eac2";
  color: rgb(226, 229, 231);
}
.icon--zip .path2:before {
  content: "\eac3";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.icon--zip .path3:before {
  content: "\eac4";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.icon--zip .path4:before {
  content: "\eac5";
  margin-left: -1em;
  color: rgb(132, 189, 90);
}
.icon--zip .path5:before {
  content: "\eac6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--zip .path6:before {
  content: "\eac7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--zip .path7:before {
  content: "\eac8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon--zip .path8:before {
  content: "\eb32";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
