@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';
@import '~assets/scss/mixins/border';

$backgroundColor: var(--text-color, $boom-ho-gray);

// theming
$zoomImageColor: var(--text-color, $boom-ho-secondary);

.document {
    &-content-media {
        &__no-radius img {
            border-radius: 0 !important;
        }

        &__container {
            border-radius: 1.5rem;
            width: auto;
            margin: 1.8rem 0;
            max-width: $layout-content-width;
            display: table;

            &--loading {
                display: none;
            }

            img {
                border-radius: 0 0 1.5rem 0;
                margin-top: 1.5rem;
                max-width: 100%;
                background-color: $boom-ho-gray-lighter;  // fallback for transparent images
            }

            figcaption {
                @include poppins-regular(1.4rem, 3.2rem);
                display: table-caption;
                caption-side: bottom;
                word-wrap: break-word;
                color: $boom-ho-white;
                padding: 2rem 1.5rem 1rem 1.5rem;
                background-color: $backgroundColor;
                border-radius: 0 0 1.5rem 1.5rem;
                margin-top: -1.2rem;

                &:first-letter {
                    text-transform: uppercase;
                }

                &.hidden {
                    display: none;
                }
            }

            &__text {
                background-color: transparent;
            }
        }

        &--audio {
            .document-content-media__container {
                @include border-radius(2.5rem 2.5rem 1.5rem 1.5rem);
                border-top: 0.2rem solid $boom-ho-white;
                background-color: $boom-ho-white;

                figcaption {
                    padding-top: 1rem;
                    margin-top: -0.5rem;
                }
            }
        }

        &__container-iframe {
            min-height: 17rem;
        }

        &--iframe,
        &--video {
            width: 100%;

            .document-content-media__container {
                width: 100%;
            }
        }
    }

    &__content-media {
        display: flex;
        overflow: hidden;
        margin: 0;
    }

    &-content-media {
        position: relative;
    }

    &-zoom-image {
        display: none;
    }

    &-content-media:hover {
        .document-zoom-image {
            display: flex;
            position: absolute;
            font-size: 2rem;
            border: 0.1rem solid $zoomImageColor;
            border-radius: 100%;
            background-color: $zoomImageColor;
            height: 4rem;
            width: 4rem;
            cursor: pointer;
            z-index: 2;
            right: 1rem;
            bottom: 1.5rem;
            color: $boom-ho-white;

            i {
                margin: auto;
                line-height: 4rem;
            }
        }
    }
}
