@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';

.document-list {
    @include poppins-regular;

    ol li::marker {
        margin-right: 1rem;
        color: $boom-ho-secondary;
    }

    ol li,
    li ol li {
        margin-left: -2rem;
    }

    li ul li {
        margin-left: -2rem;
        list-style-type: '- ';
    }

    &-unordered-list {
        list-style-type: disc;
        margin-left: -2rem;
    }

    &-ordered-list {
        margin-left: -2.5rem;
        padding-left: 0.5rem;
    }
}

td .document-list-unordered-list {
    margin-left: 0;
}
