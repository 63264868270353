@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';
@import '~assets/scss/variables';
@import '~assets/scss/mixins/border';

.assignment {
    border-radius: 1.2rem;
    margin: 0 3.5rem;
    background-color: $boom-ho-white;

    &--lg {
        margin: 0.8rem -9.5rem;
    }

    &__examine {
        @include border-radius(0.8rem);
        background-color: $boom-ho-white;

        &-text {
            @include montserrat-alternates-bold(1.8rem, 3.2rem);
            margin: auto 0 auto 3.2rem;
        }

        &-pending {
            @include border-radius(50%);
            @include montserrat-alternates-semi-bold(1.8rem);
            background-color: rgba(149, 163, 172, 0.1);
            color: $boom-ho-gray-dark;

            > span {
                margin: auto;
            }
        }

        &-wrapper {
            margin: auto 3.6rem;
        }
    }

    &__header {
        width: 100%;
        align-items: center;
        cursor: pointer;

        &--expand {
            border-bottom: 0.1rem solid $boom-ho-border-assignment;
        }

        &-bar {
            width: 100%;
            align-items: center;
            border-radius: 0.8rem;

            &--lg {
                width: 100%;
                align-items: center;
                border-radius: 0.8rem;
                margin: 0;
            }
        }

        &-icon {
            text-align: center;
            color: $boom-ho-gray;

            &-assignment {
                height: 6rem;
                width: 6rem;
                margin: 2.2rem 2.4rem 2.2rem 1.9rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $boom-ho-primary-lighter;
                color: $boom-ho-primary;
                border-radius: 1.2rem;

                &-hidden {
                    display: none;
                }
            }

            &-close {
                background-color: $boom-ho-profile;
                border-radius: 100%;
                height: 3.2rem;
                width: 3.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.7rem;
                cursor: pointer;
            }

            &-size {
                font-size: 2.625rem;
            }

            &-left {
                &-border {
                    background-color: $boom-ho-primary;
                    width: 0.5rem;
                    height: 75%;
                    border-radius: 0 2rem 2rem 0;
                    margin-left: -0.25rem;

                    &--lg {
                        background-color: $boom-ho-primary;
                        width: 0.5rem;
                        height: 70%;
                        border-radius: 0 2rem 2rem 0;
                        margin-left: -0.25rem;
                    }
                }
            }
        }

        &-hidden {
            display: none;
        }

        &-subtitle {
            @include poppins-medium(1.3rem, 1.4rem);
            color: $boom-ho-gray-dark;
            margin-bottom: 0.8rem;

            &--lg {
                color: $boom-ho-gray-dark;
                @include poppins-medium(1.5rem, 2rem);
            }
        }

        &-title {
            @include poppins-medium(1.3rem, 2rem);

            &--lg {
                @include poppins-medium(1.8rem, 2.4rem);

            }
        }

        &-open {
            box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.12);
            border-radius: 1rem 1rem 0 0;

        }

        &-block {
            margin: 2.5rem 2.5rem 2.5rem 1.4rem;
        }
    }

    &__block {
        margin-right: 3.2rem;
    }

    &__content {
        @include poppins-regular;
        margin-bottom: 2rem;

        .heading-h3 {
            @include poppins-bold(2.4rem, 1.8rem);
        }

        p {
            @include poppins-regular(1.6rem, 3.2rem);
        }

        // overruling financial table
        .table--document-table-financial {
            p {
                font-family: unset;
                font-size: unset;
                font-weight: unset;
            }
        }

        .document {
            &-list {
                // Remove default padding
                padding: unset;

                .document-list {
                    padding-left: 1.5rem;
                }

                li {
                    margin: 0 1.5rem 0 3.5rem;
                    position: relative;

                    .document {
                        &-select-block,
                        &-sortableblock,
                        &-hotspot,
                        &-graphic-gap-match-block {
                            margin-left: -2rem; // overruling default margin caused by lists
                        }

                        &-matching-block {
                            margin-left: -1rem
                        }

                        &__textarea {
                            width: calc(100% + 2rem);
                            margin: 0 0 0 -2rem;
                        }

                        &-antwoord {
                            margin: 1.4rem 0 0 -2rem;
                        }

                        &__question {
                            .document__content-media {
                                width: calc(100% + 2rem);
                                margin: 0 0 0 -2rem;
                            }
                        }
                    }

                    textarea {
                        padding: 1.6rem 0.5rem 1.6rem 1.6rem;
                        flex: 1;
                    }

                    p {
                        margin: 1.6rem 1.6rem 1.6rem 2.1rem;
                    }

                    // fix safari and chrome issues when p element is empty
                    p:empty::before {
                        content: ' ';
                        display: inline-block;
                    }

                    tbody {
                        // safari table fix
                        td:not(:has(p))::before {
                            display: block;
                            content: '';
                            margin: 1.6rem;
                        }
                    }

                    .table {

                        .document {
                            &__textarea {
                                width: 100%;
                                margin: 0;
                            }
                        }

                        textarea {
                            padding: 1rem;
                            width: 100%;
                            -webkit-box-sizing: border-box; /* <=iOS4, <= Android  2.3 */
                            -moz-box-sizing: border-box; /* FF1+ */
                            box-sizing: border-box; /* Chrome, IE8, Opera, Safari 5.1*/
                            margin: 0;
                        }
                    }

                    ol li .document {
                        &__textarea {
                            width: calc(100%);
                            margin: 0;
                        }
                    }
                }

                li::marker {
                    color: $boom-ho-primary;
                    @include poppins-regular(1.5rem, 2.8rem);
                    font-weight: 700;
                    line-height: 2.8rem;
                }

                &-ordered-list {
                    // Remove default padding
                    padding: unset;
                }

                &--vo {
                    li .document-hotspot {
                        margin-left: unset;
                    }
                }
            }
        }

        .document-select-block {
            &__initial,
            &__reviewed,
            &__correct,
            &__model {
                flex-grow: 1;
                margin-left: 0!important;
                position: relative;

                &:before {
                    @include border-radius(0.3rem);
                    content: attr(data-label);
                    white-space: nowrap;
                    position: absolute;
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    display: block;
                    line-height: 1.4rem;
                    padding: 0 .4rem;
                    top: -0.7rem;
                    left: 0.4rem;
                    color: #FFF;
                }
            }

            &__initial {
                border: 0.1rem solid $boom-ho-gray;

                &:before {
                    background-color: $boom-ho-gray;
                }

                .document-select-block__radio:checked {
                    border-color: $boom-ho-gray;

                    &:after {
                        background-color: $boom-ho-gray;
                    }
                }

                .assignment-answer__content h2 {
                    color: $boom-ho-gray;
                }

                .assignment-answer__border {
                    background-color: $boom-ho-gray;
                }
            }

            &__reviewed {
                border: 0.1rem solid $boom-vo-primary;

                &:before {
                    background-color: $boom-vo-primary;
                }

                .document-select-block__radio:checked {
                    border-color: $boom-vo-primary;

                    &:after {
                        background-color: $boom-vo-primary;
                    }
                }
            }


            &__correct {
                border: 0.1rem solid $boom-ho-orange;

                &:before {
                    background-color: $boom-ho-orange;
                }

                .document-select-block__radio:checked {
                    border-color: $boom-ho-orange;

                    &:after {
                        background-color: $boom-ho-orange;
                    }
                }
            }
        }

        > .document-list {
            &--vo {
                background-color: $boom-ho-assignment-background-color;
                box-shadow: $boom-ho-assignment-box-shadow;
            }

            background-color: $boom-ho-white;
            border-radius: 0.8rem;

            &--selected {
                outline: 0.1rem solid $boom-ho-primary;
            }

            .document-table-responsive {
                width: 100%;
                min-width: 16rem;
                margin: 0 0 2rem 0;
            }
        }

        textarea, .textarea-redactor {
            @include poppins-regular(1.5rem, 2.3rem);
            background-color: $boom-ho-textarea-question;
            border: 0.1rem solid $boom-ho-textarea-question-border;
            border-radius: 0.8rem;
            min-height: 20rem;
            resize: none;
        }

        &__score {
            @include poppins-regular(1.4rem, 2.4rem);

            &--first {
                margin-bottom: 4rem;
            }

            &--second {
                margin-top: 4rem;
            }
        }

        &__rate {
            background-color: $boom-ho-textarea-question;
            border-radius: 0.8rem;
            margin-bottom: 2rem;

            &__left {
                margin: 0 0 3.2rem 3.2rem;

                &--lg {
                    margin: 3.2rem 3.2rem 3.2rem 3.2rem;
                }

                &--explanation {
                    @include poppins-regular(1.4rem, 2.4rem);
                }
            }

            &__right {
                margin: 3.2rem 3.2rem 3.2rem 0;

                &--lg {
                    margin: 0 3.2rem 3.2rem 3.2rem;
                }

                &__vote {
                    align-items: center;

                    &--icon {
                        font-size: 4rem;
                        cursor: pointer;
                    }
                }
            }

            &__devider {
                margin: 3.2rem 2.4rem;
                background-color: $boom-ho-rate-devider
            }

            &__warning {
                margin: 0 0 -2rem 4.4rem;

                &--text {
                    color: $boom-ho-signal-red;
                    margin-left: 1.6rem;
                    @include poppins-regular(1.6rem, 3.2rem);
                }
            }
        }

        &__block {
            background-color: $boom-ho-white;
            border-radius: 0.8rem;
            margin: 0 0 4rem;

            &--title {
                @include poppins-bold(1.8rem, 3.2rem);
                margin: 5rem 0 5rem 3.2rem;
            }

            &--result {
                margin: 5rem 3.6rem 5rem 0;
            }
        }

        .document-content-mark__content-content {
            margin: 1.4rem 2rem 0 2rem;

            b {
                margin-left: 0;
            }
        }
    }

    &-answer {
        background-color: $boom-ho-gray-lighter;
        border-radius: 0.8rem;
        @include poppins-regular(1.6rem, 3.2rem);
        margin: 4rem 0;

        &__content {
            margin: 1rem 3.2rem;
            width: 100%;

            // if specific blocks needs margin styling in answer put in here
            .document-select-block--correct-answer {
                cursor: default;
                margin-block-start: 1em;
                margin-block-end: 1em;
            }

            .document {
                &-list {
                    margin-left: 2rem;

                    li {
                        margin: 0;
                    }
                }

                &-verwijzing {
                    margin: 2rem 0 ;
                }

                &-table-responsive {
                    margin: 2.3rem 0;
                }
            }
        }

        &__border {
            background-color: $boom-ho-primary;
            width: 0.5rem;
            margin: 2rem 0 2rem 0;
            border-radius: 0 2rem 2rem 0;
        }

        &--vo {
            background-color: $boom-ho-white;
            border: 0.1rem solid $boom-ho-orange;

            .assignment-answer__content h2 {
                color: $boom-ho-orange;
            }

            .assignment-answer__border {
                background-color: $boom-ho-orange;
            }
        }

        &--initial {
            border: 0.1rem solid $boom-ho-gray;

            .assignment-answer__content h2 {
                color: $boom-ho-gray;
            }

            .assignment-answer__border {
                background-color: $boom-ho-gray;
            }
        }

        &--reviewed {
            border: 0.1rem solid $boom-vo-primary;

            .assignment-answer__content h2 {
                color: $boom-vo-primary;
            }

            .assignment-answer__border {
                background-color: $boom-vo-primary;
            }
        }
    }

    &__review {
        line-height: 7.2rem;
        margin: 2rem 0;

        &--disabled {
            cursor: not-allowed;
        }

        &--text {
            @include poppins-semi-bold(1.5rem, 2.4rem);
        }
    }

    &__button {
        border: solid 0.2rem rgba(0, 36, 57, 0.16);
        color: $text-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5rem;
        width: 5rem;

        &--lg {
            width: 100%;
            height: 100%;
        }

        &-text {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &-icon {
            font-size: 1.8rem;

            &-lg {
                margin-right: 1.8rem;
            }
        }
    }

    &__star {
        margin-right: 3.2rem;
        &-count {
            @include poppins-medium(2.8rem, 3rem);
        }

        &--unvoted {
            color: $boom-ho-gray;

            &:before {
                color: inherit;
            }
        }
        &--voted {
            color: $boom-ho-gold;

            &:before {
                color: inherit;
            }
        }
    }

    .icon--vote:before {
        color: inherit;
    }

    .document--in-wrapper {
        margin: 2.4rem 4.8rem 0 4.8rem;

        .document-content-mark__content-content {
            margin-top: -0.5rem;
        }
    }

    &--clickable {
        cursor: pointer;
    }

    &__loading {
        border: 0.4rem solid $boom-ho-gray-light;
        border-top: 0.4rem solid $boom-ho-primary;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        animation: spin 2s linear infinite;

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    }
}
