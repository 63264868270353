@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';
@import '~assets/scss/mixins/typology';

$contentMarkColor: var(--content-mark-color, $boom-ho-white);
$iconBackgroundColor: var(--icon-background-color, $boom-ho-gray-lighter);
$textColor: var(--text-color, $boom-ho-gray);
$contentTextColor: var(--content-text-color, $boom-ho-primary);

.document-casus, .document-voorbeeld, .document-rekenvoorbeeld  {
    .document-content-mark {
        margin: 2rem 0;
        padding: 0 2rem;
        border-radius: 0.75rem;
        background-color: $contentMarkColor;

        .document__resource-link {
            @include poppins-bold(1.6rem);
            text-decoration: none;
        }

        .document-source-reference {
            .document-paragraph {
                @include poppins-bold;
                color: $textColor;
                text-decoration: none;
            }
        }

        &__content {
            &-border {
                padding: 0.625rem;
                border-radius: 0.875rem;
                background-color: $contentMarkColor;
            }

            &-icon {
                padding: 1.5rem;
            }

            &-positioner {
                width: 1.5rem;
            }

            &-dropdown {
                padding: 0.625rem;
                border-radius: 100%;
                margin: 2.164rem 0 2.164rem 1rem;
                cursor: pointer;
                background-color: $contentMarkColor;
            }

            .heading-h2 {
                margin-top: 1.25rem;
                color: $textColor;
            }

            a {
                @include poppins-semi-bold;
                text-decoration: none;
                color: $textColor;
            }

            &-casus > * {
                a {
                    @include poppins-bold;
                    color: $textColor;
                    text-decoration: none;
                }
            }

            &-content {
                margin-left: 0;
                width: 100%;

                @include heading-h3-content-mark-case($textColor, 2.4rem, -0.25rem) {
                    border-top: 0.1rem solid rgba(0, 117, 242, 0.1);
                    border-top: 1rem;
                    margin-right: 2.4rem;
                }

                @include heading-h4-content-mark-case($textColor);

                @include heading-h5-content-mark-case($textColor);
            }

            &-wrapper {
                padding-top: 1.5rem
            }

            &-rotate {
                transform: rotate(270deg);

                .icon--next:before {
                    color: $textColor;
                }

            }
        }
    }
}

.document-voorbeeld {
    .document-content-mark {

        .heading-h3 {
           text-align: inherit;
        }
    }
}
