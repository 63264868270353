@import '~assets/scss/variables';
@import '~assets/scss/mixins/table';
@import '~assets/scss/mixins/border';

$primaryColor: var(--primary-color, $boom-ho-white);

// Table wrapper elements
.document-table-responsive,
.document-table-financial {
    overflow: auto;
    overflow-y: auto;
    margin-bottom: 3rem;
    position: relative;

    app-document-enlarge {
        display: none;
    }

    &:hover {
        app-document-enlarge {
            display: block;
        }
    }

    @media #{$media-mobile-device} {
        width: 90vw;
    }

    td > ol {
        margin: 0 0.6rem 0 1.2rem;
    }
}

@include table {
    // @IMPORTANT
    // It's desired that most of the table layout is controlled by the content itself, the table styling applied
    // here should be limited to minimal changes like rounded borders and such. This way it's possible for the
    // content creators to customize behaviour for some of the display properties. This part of the styling should
    // and never contain any "!important" tags.

    $tableRoot: &;
    $border-size: 0.1rem;
    $background-color: #F5F5F5;
    $border-color: #A6B2B8;
    $border-radius: 0.8rem;
    $line-height: 2.5rem;
    $line-height-financial: 1.5rem;

    th {
        p {
            @include poppins-medium;
        }

        b {
            color: $boom-ho-black;
        }
    }

    td,
    .table--document-table th,
    &__caption {
        padding: 0.5rem;
    }

    tbody {
        vertical-align: top;

        td {
            padding: 0;
        }

        // safari table fix
        @supports (-webkit-touch-callout: none) {
            td:not(:has(p))::before {
                display: block;
                content: '';
                margin: 0.5rem;
            }

            .document__input-inline {
                .input-inline {
                    min-width: 1rem;
                }
            }
        }
    }

    &:not(.table--document-table-financial) {
        &, thead, tbody, tr, td, th {
            border-color: $border-color;
            text-align: unset;
            vertical-align: top;

            b {
                color: $boom-ho-black;
            }
        }
    }

    p { // There shouldn't be paragraph elements in table cells...
        font-size: inherit !important;
        line-height: inherit;
        margin-block-start: 0;
        margin-block-end: 0;
        padding: 0.5rem;
        word-break: normal;
    }

    &-content {
        &--scroll {
            overflow-x: auto;

            p {
                margin: 1.6rem;
            }
        }
    }

    &__caption {
        line-height: $line-height;
        font-size: 1.5rem;
        font-weight: 600;
        color: $boom-ho-white;
        text-align: left;
        background-color: $primaryColor;

        &:first-letter {
            text-transform: uppercase;
        }

        &:first-of-type:not([align]) {
            @include border-radius-top($border-radius);
        }

        &--financial {
            font-size: 1.3rem;
            background-color: $background-color;

            &:first-of-type {
                border-bottom: double $border-color;
            }
        }

        &--hidden {
            display: none;
        }
    }

    &--document-table {
        $documentTableRoot: &;

        border: $border-size solid $border-color;
        border-collapse: collapse;
        background-color: $boom-ho-white;

        &-financial {
            font-size: 1.3rem;
            background-color: $boom-ho-white;
            line-height: $line-height-financial;
            border: none;
        }

        .document-content-media__container {
            margin: 1rem 0;
        }

        tbody {
            .document-block .document__question-answer {
                margin: 0;

                .heading-h3--document {
                    font-size: 2.1rem;
                }
            }
        }

    }

    &--footer-caption &__caption:last-of-type {
        @include border-radius-bottom(0.8rem);
        font-style: italic;
        font-weight: 400;
    }

    .document-content-media__container img {
        background-color: $boom-ho-white;
    }

    .document-content-media__container figcaption {
        margin-top: unset;
    }
}
