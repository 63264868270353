@mixin flex($flex: 1 1 auto) {
    display: flex;
    flex: $flex;
}

@mixin flex-column($flex: 1 1 auto) {
    @include flex($flex);
    flex-direction: column;
}

@mixin flex-row($flex: 1 1 auto) {
    @include flex($flex);
    flex-direction: row;
}

.flex-column {
    @include flex-column;
}

.flex-row {
    @include flex;
}
